import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import qs from 'qs'

import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import createOffer from '../utils/createOffer'
import { isWindow, EXP_MODE, getVariant } from '../utils/commonUtil'

import addCTA from '../utils/addCTA'
import addCTAListeners from '../utils/addCTAListeners'
import setGeoTags from '../utils/geoFetch'

import {
  Banner,
  BlogBody,
  BlogContent,
  BlogWrapper,
  MiniPost,
  offerButton,
  StyledArticlesSection,
  StyledImage
} from '../styles'
import RealtedPosts from '../components/RelatedPosts'
import { below } from '../utils/media-query'
import BlogHeader from "../components/blog-post/BlogHeader";

export class BlogPostTemplate extends React.Component {
  constructor (props) {
    super(props)
    const params = isWindow ? qs.parse(window.location.search.slice(1)) : {}
    this.state = {
      isMounting: true,
      hideAds: params['no-display'] && params['no-display'] !== 'false',
      variant: EXP_MODE.DEFAULT,
      isCaptchaOk: isWindow && window.g_captcha ? window.g_captcha.isOk : false
    }
    if(isWindow){
      window.addEventListener('g_captcha', this.handleCaptchaEvent)
    }


  }
  handleCaptchaEvent=(event)=>{
    this.setState({isCaptchaOk: event.detail.isOk})
  }
  componentDidMount () {
    let variant = getVariant(this.props.alphaTest,this.props.betaTest);
    this.setState({
      isMounting: false,
      variant:variant
    })
  }

  componentWillUnmount(){
    window.removeEventListener('g_captcha', this.handleCaptchaEvent)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevState.isMounting) {
      let {
        zoneId,
        campaign,
        defaultZoneId,
        defaultCampaignHost,
        defaultOrigin,
        lincxTemplateId,
        alphaTest,
        betaTest,
        title,
        adsense
      } = this.props

      let templateId = lincxTemplateId;
      let s2 = 'Default'
      switch (this.state.variant) {
        case EXP_MODE.ALPHA:
          defaultCampaignHost = alphaTest.defaultCampaignHost || defaultCampaignHost
          campaign = alphaTest.campaign || campaign
          templateId = alphaTest.lincxTemplateId || lincxTemplateId
          s2 = (alphaTest.s2 && alphaTest.s2.trim()) || 'Alpha'
          break
        case EXP_MODE.BETA:
          defaultCampaignHost = betaTest.defaultCampaignHost || defaultCampaignHost
          campaign = betaTest.campaign || campaign
          templateId = betaTest.lincxTemplateId || lincxTemplateId
          s2 = (betaTest.s2 && betaTest.s2.trim()) || 'Beta'
          break
        default:
          s2 = 'Default'
        // do nothing
      }

      const zoneInfo = zoneId || defaultZoneId || ''
      zoneInfo &&
        campaign &&
        createOffer('', 'cta', {
          zoneId: zoneInfo,
          tag: campaign,
          domain: defaultCampaignHost,
          origin: defaultOrigin,
          s1: title,
          s2: s2,
          templateId: templateId
        })
      const tags = [...this.props.tags]
      tags.push('converted-users')
      setGeoTags()
      const offerButtons = Array.from(
        document.getElementsByClassName('offerButton')
      )
      const multiOfferButtons = Array.from(
        document.getElementsByClassName('multi-ad__btn')
      )
      offerButtons.concat(multiOfferButtons).forEach(el => {
        addCTAListeners(el, tags)
      })
    }
  }

  render () {
    let {
      content,
      contentComponent,
      description,
      author,
      adButtonText,
      adButtonUrl,
      date,
      title,
      helmet,
      banner,
      authorPicture,
      pathname,
      zoneId,
      defaultZoneId,
      campaign,
      alphaTest,
      betaTest,
      adsense
      // defaultOrigin,
      // defaultCampaignHost
    } = this.props
    const PostContent = contentComponent || Content
    const {isMounting, hideAds, isCaptchaOk} = this.state;
    if (!isMounting) {
      switch (this.state.variant) {
        case EXP_MODE.ALPHA:
          title = alphaTest.title || title
          banner = alphaTest.banner || banner
          campaign = alphaTest.campaign || campaign
          description = alphaTest.description || description
          break
        case EXP_MODE.BETA:
          title = betaTest.title || title
          banner = betaTest.banner || banner
          campaign = betaTest.campaign || campaign
          description = betaTest.description || description
          break
        default:
        // do nothing
      }
    }

    return (
      <BlogWrapper>
        {helmet ? helmet(isMounting, !adsense, isCaptchaOk) : ''}
        <BlogHeader
          pathname={pathname}
          isMounting={isMounting}
          title={title}
          author={author}
          authorPicture={authorPicture}
          date={date}
        />
        <BlogBody>
          <BlogContent data-testid="blog-content">
            <p>{description}</p>
            {this.state.isMounting ? null : (
              <PostContent
                css={offerButton}
                content={
                  addCTA(
                    '<strong>OfferButton</strong>',
                    adButtonUrl,
                    adButtonText,
                    (zoneId || defaultZoneId) && campaign
                  )
                }
              />
            )}
            <Banner>
              {banner && !isMounting ? <BannerImage fluid={banner.childImageSharp.fluid} /> : null}
            </Banner>
            {this.state.isMounting ? null : (
              <PostContent
                css={offerButton}
                loadScripts={true}
                adsense={adsense}
                content={addCTA(
                  content,
                  adButtonUrl,
                  adButtonText,
                  (zoneId || defaultZoneId) && campaign
                )}
              />
            )}
          </BlogContent>
        </BlogBody>
      </BlogWrapper>
    )
  }
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.func
}

const BannerImage = styled(Img)`
  ${below.med`
    margin: 0 1.5rem;
  `}
`

const RelatedArticlesContainer = styled(StyledArticlesSection)`
  grid-area: related;
  -ms-grid-row: 1;
  -ms-grid-column: 3;
`
const BlogPost = props => {
  const { markdownRemark: post } = props.data

  const {
    description,
    date,
    author,
    adButtonText,
    adButtonUrl,
    banner,
    tags,
    title,
    authorPicture,
    zoneId,
    campaign,
    defaultOrigin,
    defaultCampaignHost,
    lincxTemplateId,
    alphaTest,
    betaTest,
    showAds
  } = post.frontmatter

  const defaultZoneId =
    props.data.zoneIdInfo.frontmatter.ADVERSE_DEFAULT_ZONE_ID

  const renderHelmet = (isMounting, hideAds, isCaptchaOk) => {
    // render only essential meta tags when mouting or ads are hidden
    return (isMounting || hideAds || !isCaptchaOk) ? (
      <Helmet titleTemplate='Path.Money - %s'>
        <title>{`${title}`}</title>
        <meta name='description' content={`${description}`} />
      </Helmet>
    ) : (
      <Helmet titleTemplate='Path.Money - %s'>
        <title>{`${title}`}</title>
        <meta name='description' content={`${description}`} />
        <script
          async
          src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
        />
        <script>
          {`(adsbygoogle = window.adsbygoogle || []).push({
             google_ad_client: "ca-pub-6867008335629681",
             enable_page_level_ads: true
            });`}
        </script>
        <link rel='dns-prefetch' href='//exmarketplace.com' />
        <script
          async
          src='https://cdn.exmarketplace.com/bidder/adsbooster/eb_sf.js'
        />
        <script
          async
          src='https://cdn.exmarketplace.com/bidder/path_money/path_money.dfp.js'
        />
      </Helmet>
    )
  }
  return (
    <Layout IsBlogPage={!!props.data.allMarkdownRemark}>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={description}
        date={date}
        author={author}
        adButtonText={adButtonText}
        adButtonUrl={adButtonUrl}
        banner={banner}
        authorPicture={authorPicture}
        pathname={props.location.pathname}
        zoneId={zoneId}
        defaultZoneId={defaultZoneId}
        campaign={campaign}
        defaultOrigin={defaultOrigin}
        defaultCampaignHost={defaultCampaignHost}
        lincxTemplateId={lincxTemplateId}
        helmet={renderHelmet}
        tags={tags}
        title={post.frontmatter.title}
        alphaTest={alphaTest}
        betaTest={betaTest}
        adsense={showAds ?? false}
      />
      <RealtedPosts data={props.data} />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!, $country: String, $tags: [String]) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        author
        date(formatString: "MMMM DD, YYYY")
        tags
        title
        description
        country
        adButtonText
        adButtonUrl
        zoneId
        campaign
        defaultOrigin
        defaultCampaignHost
        lincxTemplateId
        showAds
        banner {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        alphaTest {
          live
          weight
          s2
          title
          description
          campaign
          defaultCampaignHost
          lincxTemplateId
          banner {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        betaTest {
          live
          weight
          s2
          title
          description
          campaign
          defaultCampaignHost
          lincxTemplateId
          banner {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        authorPicture {
          childImageSharp {
            fixed(width: 39, height: 39) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { tags: { in: $tags }, country: { eq: $country } }
        id: { ne: $id }
      },
      limit: 3
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            banner {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            betaTest {
              live
              title
              weight
              banner {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            alphaTest {
              live
              title
              weight
              banner {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            authorPicture {
              childImageSharp {
                fixed(width: 39, height: 39) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
    zoneIdInfo: markdownRemark(frontmatter: { ADVERSE_TOKEN: { ne: null } }) {
      frontmatter {
        ADVERSE_DEFAULT_ZONE_ID
      }
    }
  }
`
