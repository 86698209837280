import { requestUpush } from './addCTAListeners'

const fetchGeoInfo = () => {
  window.fetch('https://geometer.lincx.la/api/lookup')
    .then((res) => {
      return res.json()
    })
    .then((geoData) => {
      const geoCountry = 'geo-country-' + geoData.country
      const geoRegion = 'geo-region-' + geoData.region
      // delay initial notification ask popup, let the user spend some time
      // requestUpush([geoCountry, geoRegion], true)
    })
    .catch((error) => {
      console.log(error)
    })
}

const geoTagsPresent = (tags) => {
  let countryTagPresent = false
  let regionTagPresent = false
  const countryRegex = /geo-country/g
  const regionRegex = /geo-region/g

  tags.forEach((tag) => {
    if (countryRegex.test(tag)) countryTagPresent = true
    if (regionRegex.test(tag)) regionTagPresent = true
  })

  return countryTagPresent && regionTagPresent
}

const setGeoTags = () => {
  let tags = []
  const persistedTags = window.localStorage.getItem('tags')
  if (persistedTags) tags = JSON.parse(persistedTags)
  if (!geoTagsPresent(tags)) fetchGeoInfo()
}

export default setGeoTags