import React from 'react'
import { Link } from 'gatsby'
import { EXP_MODE, getVariant } from '../utils/commonUtil'
import styled from '@emotion/styled'
import { above, below } from '../utils/media-query'
import Img from 'gatsby-image'

export default function RealtedPosts({ data }) {
  if (!data.allMarkdownRemark) return

  const { edges: posts } = data.allMarkdownRemark
  return (
    <RelatedPostsContainer>
      <h2>Related Article</h2>
      <div>
        {posts.map(({ node: post }) => {
          let {
            frontmatter: { title, date, banner, alphaTest, betaTest },
            fields: { slug }
          } = post

          switch (getVariant(alphaTest, betaTest)) {
            case EXP_MODE.ALPHA:
              title = alphaTest.title || title
              banner = alphaTest.banner || banner
              break
            case EXP_MODE.BETA:
              title = betaTest.title || title
              banner = betaTest.banner || banner
              break
            default:
            // do nothing
          }
          return (
            <MiniPost key={slug} data-testid="related-articles">
              <Link to={slug}>
                {banner && <RelatedImage fluid={banner.childImageSharp.fluid} />}
              </Link>
              <header>
                <div>
                  <h3>
                    <Link to={slug}>{title}</Link>
                  </h3>
                  <time>{date}</time>
                </div>
              </header>
            </MiniPost>
          )
        })}
      </div>
    </RelatedPostsContainer>
  )
}

const RelatedPostsContainer = styled.div`
  h2 {
    text-transform: none;
    font-family: 'Poppins', sans-serif;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.29;
    letter-spacing: normal;
    color: #3c3b3b;
    margin-bottom: 0px;
    text-align: start;
    ${below.med`
      font-size: 18.9px;
    `}
  }
  @media (min-width: 1281px) {
    padding-top: 12rem;
    width: 330px;
  }
  ${below.med`
    padding: 0 1.5rem
  `}
`

const MiniPost = styled.article`
  position: relative;
  a {
    width: 100%;
    overflow: hidden;
    display: inline-block;
    color: #3c3b3b;
  }
  header {
    padding: 10px 1.5rem 18px 0px;
    display: flex;
    align-items: center;
    img {
      border-radius: 50%;
    }
    h3 {
      text-transform: none;
      font-family: Poppins;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: #3c3b3b;
      margin-bottom: 0px;
      ${below.med`
        font-size: 16px;
      `}
    }
    time {
      opacity: 0.5;
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #3c3b3b;
    }
  }
`

const RelatedImage = styled(Img)`
  transition: transform 0.2s ease-out;
  height: 186px;
  ${below.med`
    height: 163px;
  `}
  &:hover {
    transform: scale(1.05);
  }
`
