import styled from '@emotion/styled'
import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { below } from '../../utils/media-query'
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter
} from 'react-icons/fa'
import { FiMail } from 'react-icons/fi'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton
} from 'react-share'

export default function BlogHeader({
  pathname,
  isMounting = false,
  title,
  author,
  authorPicture,
  date
}) {
  const url = typeof window !== 'undefined' && window.location.href
  return (
    <Header>
      <TitleContainer>
        <Link to={pathname}>
          <h2>{isMounting ? '' : title}</h2>
        </Link>
      </TitleContainer>
      <MetaWithSocial>
        <Meta>
          <div className="meta-image">
            {author && authorPicture && authorPicture.childImageSharp ? (
              <Img fixed={authorPicture.childImageSharp.fixed} />
            ) : null}
          </div>
          <div className="meta-description">
            <MetaAuthor>
              <span>{author}</span>
            </MetaAuthor>
            <time>{date}</time>
          </div>
        </Meta>
        <Socials>
          <TwitterIconContainer url={url}>
            <FaTwitter />
          </TwitterIconContainer>

          <FacebookIconContainer url={url}>
            <FaFacebookF />
          </FacebookIconContainer>

          <LinkedinIconContainer url={url}>
            <FaLinkedinIn />
          </LinkedinIconContainer>

          <EmailIconContainer
            url={url}
            subject={isMounting ? '' : title}
            body={
              'Hey there! Thought you might be interested in this article from Path.Money:'
            }
          >
            <FiMail />
          </EmailIconContainer>
        </Socials>
      </MetaWithSocial>
    </Header>
  )
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  ${below.med`
    flex-direction: column;
    padding: 3rem 1.5rem 0.5rem;
  `}
  ${below.small`
    padding: 1.75rem 1.5rem 0rem;
    time{
      display: none;
    }
  `}
`

const TitleContainer = styled.div`
  h1 {
    font-size: 1.75rem;
    margin: 0;
    &:hover {
      color: #2ebaae;
    }
  }

  h2 {
    font-family: Poppins;
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #3c3b3b;
    text-transform: none;
  }

  ${below.med`
    h1 {
      text-align: center;
    }
    h2 {
      font-family: Poppins;
      font-size: 1.5rem !important;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.27;
      letter-spacing: normal !important;
      color: #3c3b3b;
      text-align: start;
      text-transform: none;
      margin-bottom: 8px;
    }
  `}
`

const Meta = styled.div`
  display: flex;
  padding: 36px 0rem 34px;
  .meta-image {
    img {
      border-radius: 100%;
      display: block;
      max-width: 2.4rem;
      max-height: 2.4rem;
    }
  }
  .meta-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1rem;
    justify-content: center;
    time {
      font-family: 'Source Sans Pro', sans-serif;
      opacity: 0.5;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }

  ${below.med`
    padding: 0;
    border: 0;
    align-items: center;
    min-width: 0;
    flex-direction: row;
    .meta-image {
      display: none;
    }
    .meta-description{
      padding: 0;
    }
    time {
      margin: 0;
    }
    a {
      &::before {
        text-transform: none;
        content: "by : ";
        font-size: 0.8rem;
        color: #aaa;
      }
    }
`}
`

const MetaAuthor = styled.span`
  opacity: 0.8;
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #1c7a70;
  margin-bottom: 2px;
`

const MetaWithSocial = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Socials = styled.div`
  ${below.med`
    display: flex;
  `}
`

const ShareIconStyle = `
  font-size: 1rem;
  margin-left: 1.5rem;
  color: #aaa !important;
  ${below.med`
    font-size: 13px;
  `}
`
const FacebookIconContainer = styled(FacebookShareButton)`
  ${ShareIconStyle}
`
const TwitterIconContainer = styled(TwitterShareButton)`
  ${ShareIconStyle}
`
const LinkedinIconContainer = styled(LinkedinShareButton)`
  ${ShareIconStyle}
`
const EmailIconContainer = styled(EmailShareButton)`
  ${ShareIconStyle}
`
